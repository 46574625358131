import { ItemTypeLabel } from "domain/Inventory";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCensusContext } from "domain/Census";
import clsx from "clsx";
import "./item-parts-table.scss";

export const ItemPartsTable = ({ row }) => {
  const { activeCensus } = useCensusContext();
  const { parts, location } = row.original;
  const navigate = useNavigate();
  const goRouteId = useCallback(
    (id) => {
      navigate(`/item/${id}`);
    },
    [navigate]
  );
  const ColumnElement = useCallback(
    ({ cell, part }) => {
      switch (cell.column.id) {
        case "button":
        case "selection":
          return null;
        case "navigatorId":
          const { navigatorId } = part;
          return <div>{navigatorId.split("::")[1]}</div>;
        case "itemId":
          return <div>{part.itemId}</div>;
        case "type":
          return <ItemTypeLabel type={part.type} />;
        case "category.name":
          return <div>{part.category.name}</div>;
        case "company.owner":
          return <div>{part.owner?.name}</div>;
        case "label":
          return <div>{part.label}</div>;
        case "assignedTo":
          return <div>{part.assignedTo}</div>;
        case "invoice.number":
          return <div>{part.invoice?.number}</div>;
        case "location.office":
          const { office, city } = location;
          return (
            <div>
              <div>{office}</div>
              <div className="items-table__data__cell-sub-text">{city}</div>
            </div>
          );
        default:
          return cell.render("Cell");
      }
    },
    [location]
  );

  return (
    <div className="items-table__row-parts">
      {parts.map((part, index) => {
        const partKey = `${part.navigatorId || part.itemId} - ${index}`;
        return (
          <div
            className="items-table__row-part items-table__grid"
            onClick={() => goRouteId(part.itemId)}
            key={partKey}
            style={{ "--grid-size": row.cells.length }}
          >
            {row.cells.map((cell, index) => (
              <div
                {...cell.getCellProps()}
                key={index}
                className={clsx("items-table__data", {
                  "items-table__data--mobile-hide-label": cell.column.id === "selection",
                })}
                data-label={cell.column.render("Header")}
              >
                <div>
                  <ColumnElement cell={cell} row={row} part={part} />
                  {cell.column.Header === "Korisnik" ? <small className="items-table__owner">(Korisnik)</small> : null}
                  {cell.column.Header === "Vlasnik" ? <small className="items-table__owner">(Vlasnik)</small> : null}
                </div>
              </div>
            ))}
            {activeCensus && (
              <div
                className={clsx("census-indicator", {
                  incomplete: true,
                })}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};
